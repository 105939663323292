<template>
  <surround-box background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)" specialColor="#0c4358" style="margin: 1.5vh 0;" title="荣昌区土壤承载力">
    <div class="content-box">
      <div class="left">
        <div class="row-one" style="margin-top: 15px">
          <div class="img-box">
            <!-- <img src="/imgs/pig.png" /> -->
            <div ref="pig" style="width: 100px; height: 100px"></div>
          </div>
          <!-- <span>{{ count }}</span> -->
        </div>
        <div class="line"></div>
        <div class="line-font">区域承载力</div>
      </div>
      <p class="iconfont">&#xe6e6;</p>
      <div class="right">
        <div class="num" style="margin-top: 15px; margin-bottom: 20px">
          <span v-for="(item, index) in count" :key="index" :class="[item == '.' ? 'point-place' : 'number-place']">{{ item }}</span>
          <div class="row-1">约等于</div>
          <div class="row-2">当季每亩地{{ count.join('') }}头猪</div>
        </div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from '@/components/mapComp/surroundBox4.vue'
import * as SVGA from 'svgaplayerweb'

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      count: [],
    }
  },
  methods: {
    getList() {
      this.$post('statistics/capacityByNum', {
        resourceTypeId: 158,
        companyNo:'administrator',
      }).then((res) => {
        if (res && res[0]) {
          res.sort((a, b) => {
            return +b.years - +a.years
          })
          this.count = [res[0].num]
        }
      })
    },
    initSvga(dom, url) {
      let player = new SVGA.Player(dom)
      let parser = new SVGA.Parser(dom)
      parser.load(url, (videoItem) => {
        player.setVideoItem(videoItem)
        player.startAnimation()
      })
    },
  },
  mounted() {
    this.getList()
    this.initSvga(this.$refs.pig, '/svga/pigTwo')
  },
}
</script>
<style lang='less' scoped>
.content-box {
  width: 100%;
  height: 230px;
  position: relative;
  display: flex;

  .left {
    flex-grow: 0;
    flex-shrink: 0;
    width: 2.4rem;
    padding: 32px;

    .row-one {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #36d8ff;
      opacity: 1;
      // padding-bottom: 6px;
      .img-box {
        // padding-right: 32px;
        // margin-left: 42px;
      }
    }

    .line {
      width: 100%;
      height: 12px;
      border-radius: 8px;
      // background: linear-gradient(
      //   180deg,
      //   rgba(186, 255, 125, 0.52) 0%,
      //   rgba(35, 166, 242, 0.38) 100%
      // );
      background: linear-gradient(
        180deg,
        rgba(148, 125, 255, 0.63) 0%,
        rgba(35, 166, 242, 0.33) 100%
      );
      // border: 1px solid #3affa4;
      border: 1px solid #0090ff;
    }

    .line-font {
      width: 100%;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #ffffff;
      opacity: 1;
      text-align: center;
      padding-top: 6px;
    }
  }
  > .iconfont {
    width: 0.42rem;
    height: 0.42rem;
    position: absolute;
    left: 52%;
    bottom: 20%;
    color: #a1bdd4;
  }
  .right {
    padding-top: 20px;
    padding-left: 10px;
    .num {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      .row-1 {
        color: #fff;
        padding: 10px 0;
      }

      .row-2 {
        color: #fff;
      }
      .number-place {
        padding: 2px 6px;
        border: 1px solid #36d8ff;
        border-radius: 6px;
        color: #36d8ff;
        font-size: 48px;
        font-weight: bold;
        width: 1rem;
        height: 1rem;
        background: rgba(0, 239, 253, 0.09);
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
          margin-left: 12px;
        }
      }

      .point-place {
        margin-left: 8px;
        color: #36d8ff;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
</style>
