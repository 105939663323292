<template>
  <div :style="{ width: width + 'rem' }" class="surround-box">
    <div class="title">
      {{ title }}
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    width: {
      type: Number,
      default: 4.13,
    },
    background: String,
    fontSize: {
      type: Number,
      default: 18,
    },
    specialColor: String,
  },
};
</script>
<style lang='less' scoped>
.surround-box {
  position: relative;
  height: 29.51851vh;
  background: url(../../assets/imgs/surroundBox4.png) left top no-repeat;
  background-size: 100% 100%;
  .title {
    color: #fff;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-align: left;
    padding-bottom: 1.22407vh;
    padding-top: 0.35vh;
    padding-left: 0.55rem;
    font-weight: 700;
    letter-spacing: 1px;
    img{
      width: 0.3rem;
      margin: 0 0.16rem;
    }
  }
}
</style>